@keyframes moveTwinkBack {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -10000px 5000px;
    }
  }
  
  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #111 url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/stars.png) repeat top center;
    z-index: -1;
    animation: moveTwinkBack 200s linear infinite;
  }


.technology a {
    color: #b2ffff; /* light teal */
    text-decoration: none; /* remove the underline */
  }
  
  .technology a:hover {
    color: #92e0ce; /* a slightly darker teal for hover effect */
    text-decoration: underline; /* add underline on hover */
  }
  