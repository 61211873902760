body {
    padding: 25px;
    background-color: black;
    color: white;
    font-size: 25px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
 }
 .dark-mode {
    background-color: black;
    color: white;
 }
 .toggleButton {
    padding: 12px;
    font-size: 18px;
    border: 2px solid green;
 }